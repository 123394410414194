<template>
  <div>
    <div class="top-banner">
      <div class="banner-text">
        <div class="banner-title">企业地图服务</div>
        <div class="banner-subtitle">
          为企业轻松构建专属地图，在PC端、手机端、智能穿戴设备等平台提供数据可视化展示及交互能力。地图服务具备底图加载、全要素搜索、图层控制等方面能力，满足各场景对地图的需求。
        </div>
      </div>
    </div>

    <div class="process">
      <div class="type-area">
        <div class="base-top">
          <div class="dot-plot">操作控制</div>
        </div>
        <div class="process-main">
          <div class="text-box">
            <div>
              <div class="label-text">业务处理</div>
              <div class="content-text">
                点选要素即可打开业务详情页面，直接进行事务流转、音视频通话、信息提交等操作处置。
              </div>
            </div>
            <div>
              <div class="label-text">要素显示</div>
              <div class="content-text">
                全量要素控制台精确控制各要素的显示，快速形成各类专题地图。
              </div>
            </div>
            <div>
              <div class="label-text">基础功能</div>
              <div class="content-text">
                支持地图的下钻、拖动、缩放、距离/面积测量、标注等操作。
              </div>
            </div>
          </div>
          <div class="img-box">
            <img src="@/assets/images/banner32.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="process systems">
      <div class="type-area">
        <div class="base-top">
          <div class="dot-plot">要素检索</div>
        </div>
        <div class="process-main">
          <div class="img-box">
            <img src="@/assets/images/banner33.png" alt="" />
          </div>

          <div class="text-box">
            <div>
              <div class="label-text">全局搜索</div>
              <div class="content-text">
                基于自建空间数据库组织系统内所有GIS资源，通过ES搜索引擎实现全局空间数据查询，支持模糊搜索、分类过滤、标签过滤等多种方式进行资源的快速定位。
              </div>
            </div>
            <div>
              <div class="label-text">框选统计</div>
              <div class="content-text">
                通过地理空间范围对资源进行检索，快速查找位于指定空间范围内的资源，支持分类过滤、标签过滤。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="engine">
      <div class="type-area">
        <div class="base-top">
          <div class="dot-plot">地图元素绘制</div>
        </div>
        <div class="engine-main">
          <div class="engine-header">
            多种形式地图元素绘制能力，实现各类型要素上图显示，快速建立业务地图。
          </div>
          <div class="img-text">
            <div>
              <div class="img-box">
                <img src="@/assets/images/dhz.png" alt="" />
              </div>
              <div class="text-box">点绘制</div>
            </div>
            <div>
              <div class="img-box">
                <img src="@/assets/images/xhz.png" alt="" />
              </div>
              <div class="text-box">线绘制</div>
            </div>
            <div>
              <div class="img-box">
                <img src="@/assets/images/mhz.png" alt="" />
              </div>
              <div class="text-box">面绘制</div>
            </div>
            <div>
              <div class="img-box">
                <img src="@/assets/images/rlt.png" alt="" />
              </div>
              <div class="text-box">热力图</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="process systems">
      <div class="type-area">
        <div class="base-top">
          <div class="dot-plot">底图接入</div>
        </div>
        <div class="process-main">
          <div class="text-box">
            <div>
              <div class="label-text">三方地图</div>
              <div class="content-text">
                支持接入高德地图、百度地图、天地图、谷歌地图等互联网地图。
              </div>
            </div>
            <div>
              <div class="label-text">自建地图</div>
              <div class="content-text">
                以服务形式引入企业自建地图，支持WMS、WMTS、WFS等多种格式。
              </div>
            </div>

            <div>
              <div class="label-text">更多数据底图</div>
              <div class="content-text">
                支持叠加TIFF、倾斜摄影OSGB、BIM、CAD等格式文件。
              </div>
            </div>
          </div>

          <div class="img-box">
            <img src="@/assets/images/banner34.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="ddzc-box">
      <img src="@/assets/images/ddzc.png" alt="" />
    </div>

    <div class="advantage">
      <div class="type-area">
        <div class="divider">
          <el-divider>产品优势</el-divider>
        </div>
        <div class="advantage-main">
          <div class="item" v-for="item in advantageList">
            <img :src="item.img" alt="" />
            <span>{{ item.title }}</span>
            <div>{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 联系我们 -->
    <contact-us></contact-us>
  </div>
</template>

<script>
import ContactUs from "../home/ContactUs";
export default {
  name: "mapServices", // 开放能力

  components: {
    ContactUs,
  },

  data() {
    return {
      advantageList: [
        {
          title: "多种格式地图",
          content: "支持WMS、WMTS、WFS等多种格式地图接入",
          img: require("@/assets/images/dzgsdt.png"),
        },
        {
          title: "多坐标系支持",
          content: "支持Wbe Mercator、WGS84、China2000、地方坐标系等坐标系",
          img: require("@/assets/images/dzbxzc.png"),
        },
        {
          title: "数据编辑更新",
          content: "支持业务空间数据在线编辑，并实时更新至地图",
          img: require("@/assets/images/sjbjgx.png"),
        },
        {
          title: "数据权限控制",
          content: "根据用户角色精确控制地图上要素的显示类型和区域",
          img: require("@/assets/images/sjqxkz.png"),
        },
        {
          title: "业务定制开发",
          content: "调用开放接口，融合流程引擎等能力，快速拓展升级功能",
          img: require("@/assets/images/ywdzkf.png"),
        },
        {
          title: "丰富的图表组件",
          content: "基于报表引擎快捷实现柱状图、折线图、饼状图等多种可视化图表",
          img: require("@/assets/images/tbzj.png"),
        },
      ],
    };
  },

  methods: {},
};
</script>

<style lang="less" scoped>
div {
  box-sizing: border-box;
}
img {
  object-fit: cover;
}

.dot-plot {
  width: 1180px;
  height: 137px;
  text-align: left;
  background: url("~@/assets/images/wangdian.png") no-repeat;
  background-size: 1180px 137px;
  padding-right: 16px;
  padding-top: 36px;
  font-size: 28px;
  line-height: 28px;
}

.divider {
  padding: 0px 250px;
  & /deep/.el-divider__text {
    font-size: 36px;
    font-weight: 400;
    color: #17191f;
    line-height: 36px;
    padding: 0 52px;
    background-color: #fbfbfb;
  }
  &/deep/.el-divider {
    background-color: #d1d6e0;
    height: 1px;
  }
}

.top-banner {
  height: 580px;
  width: 100%;
  background-image: url("~@/assets/images/banner.png");
  background-color: #010d14;
  background-size: 1800px 580px;
  background-position: top;
  background-repeat: no-repeat;
  color: #ffffff;
  overflow: hidden;
  .banner-text {
    width: 1180px;
    margin: 0 auto;
  }
  .banner-title {
    font-size: 60px;
    font-weight: 400;
    line-height: 82px;
    margin-top: 166px;
  }
  .banner-subtitle {
    font-size: 16px;
    line-height: 30px;
    margin-top: 27px;
    width: 454px;
    height: 90px;
    color: #b6bfd3;
  }
}
.base-top {
  height: 118px;
}
.process {
  background: #ffffff;
  height: 733px;
  padding-top: 80px;

  .process-main {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    .text-box {
      width: 510px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-shrink: 0;
      & > div {
        margin-bottom: 19px;
        width: 100%;
        .label-text {
          width: 100%;
          font-family: HarmonyOS_Sans;
          font-weight: 500;
          font-size: 20px;
          color: #1e1e1e;
          letter-spacing: 0;
          margin-bottom: 21px;
        }
        .content-text {
          width: 100%;
          font-family: HarmonyOS_Sans;
          font-weight: SC;
          font-size: 16px;
          color: #797c80;
          letter-spacing: 0;
          line-height: 26px;
        }
      }
    }
    .img-box {
      flex-shrink: 0;
      width: 557px;
      height: 356px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.systems {
  height: 777px;
  background: #f1f2f666;

  .dot-plot {
    text-align: right;
  }
  .process-main {
    .img-box {
      width: 590px;
      height: 400px;
    }
  }
}

.engine {
  background: #ffffff;
  height: 643px;
  padding-top: 80px;

  .engine-main {
    margin-top: 80px;
    .engine-header {
      width: 100%;
      text-align: center;
      font-family: HarmonyOS_Sans;
      font-weight: SC;
      font-size: 16px;
      color: #797c80;
      letter-spacing: 0;
      line-height: 26px;
      margin-bottom: 40px;
    }
    .img-text {
      display: flex;
      justify-content: space-between;
      & > div {
        .img-box {
          width: 280px;
          height: 158px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .text-box {
          font-weight: SC;
          font-size: 16px;
          color: #797c80;
          letter-spacing: 0;
          text-align: center;
          line-height: 26px;
          margin-top: 16px;
        }
      }
    }
  }
}

.advantage {
  height: 759px;
  background: #ffffff;
  padding-top: 80px;
  &/deep/.el-divider__text {
    background: #ffffff;
  }
  .advantage-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: -21px;
    .item {
      flex: 0 1 25.1%;
      height: 225px;
      display: flex;
      flex-direction: column;
      margin-top: 80px;
      align-items: center;
      img {
        width: 90px;
        height: 90px;
      }
      span {
        font-size: 20px;
        font-weight: 500;
        color: #1e1e1e;
        line-height: 20px;
        margin: 12px 0 17px 0;
      }
      div {
        width: 260px;
        font-size: 16px;
        color: #1e1e1eab;
        text-align: center;
        line-height: 28px;
      }
    }
  }
}

.ddzc-box {
  width: 100%;
  height: 334px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
