var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"advantage"},[_c('div',{staticClass:"type-area"},[_c('div',{staticClass:"divider"},[_c('el-divider',[_vm._v("产品优势")])],1),_c('div',{staticClass:"advantage-main"},_vm._l((_vm.advantageList),function(item){return _c('div',{staticClass:"item"},[_c('img',{attrs:{"src":item.img,"alt":""}}),_c('span',[_vm._v(_vm._s(item.title))]),_c('div',[_vm._v(_vm._s(item.content))])])}),0)])]),_c('contact-us')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-banner"},[_c('div',{staticClass:"banner-text"},[_c('div',{staticClass:"banner-title"},[_vm._v("企业地图服务")]),_c('div',{staticClass:"banner-subtitle"},[_vm._v(" 为企业轻松构建专属地图，在PC端、手机端、智能穿戴设备等平台提供数据可视化展示及交互能力。地图服务具备底图加载、全要素搜索、图层控制等方面能力，满足各场景对地图的需求。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"process"},[_c('div',{staticClass:"type-area"},[_c('div',{staticClass:"base-top"},[_c('div',{staticClass:"dot-plot"},[_vm._v("操作控制")])]),_c('div',{staticClass:"process-main"},[_c('div',{staticClass:"text-box"},[_c('div',[_c('div',{staticClass:"label-text"},[_vm._v("业务处理")]),_c('div',{staticClass:"content-text"},[_vm._v(" 点选要素即可打开业务详情页面，直接进行事务流转、音视频通话、信息提交等操作处置。 ")])]),_c('div',[_c('div',{staticClass:"label-text"},[_vm._v("要素显示")]),_c('div',{staticClass:"content-text"},[_vm._v(" 全量要素控制台精确控制各要素的显示，快速形成各类专题地图。 ")])]),_c('div',[_c('div',{staticClass:"label-text"},[_vm._v("基础功能")]),_c('div',{staticClass:"content-text"},[_vm._v(" 支持地图的下钻、拖动、缩放、距离/面积测量、标注等操作。 ")])])]),_c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/images/banner32.png"),"alt":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"process systems"},[_c('div',{staticClass:"type-area"},[_c('div',{staticClass:"base-top"},[_c('div',{staticClass:"dot-plot"},[_vm._v("要素检索")])]),_c('div',{staticClass:"process-main"},[_c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/images/banner33.png"),"alt":""}})]),_c('div',{staticClass:"text-box"},[_c('div',[_c('div',{staticClass:"label-text"},[_vm._v("全局搜索")]),_c('div',{staticClass:"content-text"},[_vm._v(" 基于自建空间数据库组织系统内所有GIS资源，通过ES搜索引擎实现全局空间数据查询，支持模糊搜索、分类过滤、标签过滤等多种方式进行资源的快速定位。 ")])]),_c('div',[_c('div',{staticClass:"label-text"},[_vm._v("框选统计")]),_c('div',{staticClass:"content-text"},[_vm._v(" 通过地理空间范围对资源进行检索，快速查找位于指定空间范围内的资源，支持分类过滤、标签过滤。 ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"engine"},[_c('div',{staticClass:"type-area"},[_c('div',{staticClass:"base-top"},[_c('div',{staticClass:"dot-plot"},[_vm._v("地图元素绘制")])]),_c('div',{staticClass:"engine-main"},[_c('div',{staticClass:"engine-header"},[_vm._v(" 多种形式地图元素绘制能力，实现各类型要素上图显示，快速建立业务地图。 ")]),_c('div',{staticClass:"img-text"},[_c('div',[_c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/images/dhz.png"),"alt":""}})]),_c('div',{staticClass:"text-box"},[_vm._v("点绘制")])]),_c('div',[_c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/images/xhz.png"),"alt":""}})]),_c('div',{staticClass:"text-box"},[_vm._v("线绘制")])]),_c('div',[_c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/images/mhz.png"),"alt":""}})]),_c('div',{staticClass:"text-box"},[_vm._v("面绘制")])]),_c('div',[_c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/images/rlt.png"),"alt":""}})]),_c('div',{staticClass:"text-box"},[_vm._v("热力图")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"process systems"},[_c('div',{staticClass:"type-area"},[_c('div',{staticClass:"base-top"},[_c('div',{staticClass:"dot-plot"},[_vm._v("底图接入")])]),_c('div',{staticClass:"process-main"},[_c('div',{staticClass:"text-box"},[_c('div',[_c('div',{staticClass:"label-text"},[_vm._v("三方地图")]),_c('div',{staticClass:"content-text"},[_vm._v(" 支持接入高德地图、百度地图、天地图、谷歌地图等互联网地图。 ")])]),_c('div',[_c('div',{staticClass:"label-text"},[_vm._v("自建地图")]),_c('div',{staticClass:"content-text"},[_vm._v(" 以服务形式引入企业自建地图，支持WMS、WMTS、WFS等多种格式。 ")])]),_c('div',[_c('div',{staticClass:"label-text"},[_vm._v("更多数据底图")]),_c('div',{staticClass:"content-text"},[_vm._v(" 支持叠加TIFF、倾斜摄影OSGB、BIM、CAD等格式文件。 ")])])]),_c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("@/assets/images/banner34.png"),"alt":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ddzc-box"},[_c('img',{attrs:{"src":require("@/assets/images/ddzc.png"),"alt":""}})])
}]

export { render, staticRenderFns }